import Cal, { getCalApi } from "@calcom/embed-react"
import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

const WorkBeta = () => {
  const product = "BBE Work"
  const submitUrl = "https://submit-form.com/MKYKSK9sQ"

  const [email, setEmail] = useState("")
  const [website, setWebsite] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()
    const formData = {
      email,
      website,
      product,
    }

    try {
      const response = await fetch(submitUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      })

      if (response.ok) {
        alert("Form submitted successfully")
        setEmail("")
        setWebsite("")
      } else {
        alert("Error submitting form")
      }
    } catch (error) {
      alert("Error submitting form")
    }
  }

  useEffect(() => {
    ;(async function () {
      const cal = await getCalApi({})
      cal("ui", {
        theme: "light",
        styles: { branding: { brandColor: "#000000" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      })
    })()
  }, [])
  return (
    <Layout>
      <div>
        <div className="flex justify-center">
          <StaticImage
            src="../images/bb_logo.png"
            alt="BBEnergy.AI logo"
            style={{ maxWidth: `300px` }}
          />
        </div>
        <div className="w-fit mx-auto p-6 mt-12 bg-white rounded-2xl ring-1 ring-inset ring-gray-900/5">
          <div className="flex flex-col gap-[12px]">
            <h1 className="text-[#141414] text-[24px] font-semibold">
              Thanks for your interest in Big Brain Energy!
            </h1>
            <p className="text-[#141414] text-[18px] font-medium leading-[150%] max-w-lg">
              We’re just putting the finishing touches to the experience and
              will be releasing the beta to select partners in a few weeks. To
              participate, please book a quick call with us below.
            </p>
            <p className="text-[#141414] text-[18px] leading-[150%] max-w-lg">
              <span className="font-semibold">
                All users get unlimited free access to Big Brain Energy while
                we're in closed beta 🙌
              </span>{" "}
              (this is not a sales call, we just want to get to know you)
            </p>
            <p className="text-[#141414] text-[18px] leading-[150%] max-w-lg">
              Alternatively, just put down your email below and we'll ping you
              when the platform is open for everyone:
            </p>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  type="website"
                  id="website"
                  name="website"
                  value={website}
                  placeholder="Your Company Website"
                  onChange={e => setWebsite(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <textarea
                  id="email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Your Work Email"
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-black text-white p-2 rounded-md hover:bg-blue-600"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <Cal
          calLink="dimatar/big-brain-energy-intro"
          style={{ width: "100%", height: "100%", overflow: "scroll" }}
          config={{ layout: "month_view" }}
        />
        ;
      </div>
    </Layout>
  )
}

export default WorkBeta
